import React from 'react';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import './HomePage.css';
const { REACT_APP_THINKHAUS_URL } = process.env;

const NavBar: React.FC = () => {
  const { user, signOut, session, role } = useAuth();
  const navigate = useNavigate();

  const isAuthenticated = session !== null;

  // Function to navigate to login
  const signIn = () => {
    navigate('/login');
  };

  // Define items for Menubar based on user authentication and role
  const items = [
    // Log In or Log Out based on authentication status
    isAuthenticated
      ? { label: 'Log Out', icon: 'pi pi-sign-out', command: () => signOut() }
      : { label: 'Log In', icon: 'pi pi-sign-out', command: () => signIn() },

    // Conditional rendering of menu items based on user role
    ...(isAuthenticated && role === 'internal'
      ? [
        {
          label: 'Description-generator',
          icon: 'pi pi-info-circle',
          command: () => navigate('/generator'),
          id: 'nav-generator',
        },
      ]
      : []),

    {
      label: 'Intake-form',
      icon: 'pi pi-file',
      command: () => navigate('/kroger-intake-form'),
      id: 'nav-intake',
    },

    ...(isAuthenticated &&
      ['marks_user', 'kroger_user', 'internal'].includes(role as string)
      ? [
        {
          label: 'Marks-Image-upload',
          icon: 'pi pi-upload',
          command: () => navigate('/marks-single-image-upload'),
          id: 'nav-imageupload',
        },
      ]
      : []),

    ...(isAuthenticated &&
      ['purered_user', 'kroger_user', 'internal'].includes(role as string)
      ? [
        {
          label: 'PureRED-Image-upload',
          icon: 'pi pi-upload',
          command: () => navigate('/purered-single-image-upload'),
          id: 'nav-pureredimageupload',
        },
      ]
      : []),

    ...(isAuthenticated
      ? [
        {
          label: "Other-Kroger-Tools",
          icon: 'pi pi-spin pi-cog',
          items: [
            {
              label: 'UPC Search',
              icon: 'pi pi-search',
              command: () => navigate('/upc-search'),
              id: 'nav-upc-search',
            },
            {
              label: 'Create UPC',
              icon: 'pi pi-plus-circle',
              command: () => navigate('/construction'),
              id: 'nav-upc-create'
            }
          ]
        },
      ]
      : []),
  ];


  return (
    <>
      <Menubar model={items} id='top-nav' />
    </>
  );
};
export default NavBar;

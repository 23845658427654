import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "../../App.css";

import MainTable from '../MainTable/MainTable';
import { UploadButton } from '../UploadButton/UploadButton';
import { ExportButtons } from '../ExportButtons/ExportButtons';
import { Loader } from '../Loader/Loader';
import { RunAllButton } from '../RunAllButton/RunAllButton';
import { DropdownSelect } from '../DropdownSelect/DropdownSelect'
import { SyndigoSubmitButton } from '../SyndigoSubmitButton/SyndigoSubmitButton';
import { ImageOverrideDropdown } from '../ImageOverride/ImageOverride'
import { Tooltip } from 'primereact/tooltip';
import { useNavigate } from 'react-router-dom';
import { useGeneratorContext } from '../../context/GeneratorContext';
import { ThinkhausHeaderTitle } from '../Generator/ThinkhausHeaderTitle';

const wordLists = {
    bannedWords: ['practical', 'straightforward', 'free form', 'wholesome', 'all', 'only', 'pure', 'real', 'healthy', 'boost', 'well-balanced', 'hand-crafted', 'all natural', 'natural', 'minimally precessed', 'processed', 'simple', 'protein packed', 'full of protein', 'muscle-building', 'freshness', 'fresh', 'source of energy', 'trace', 'clean', 'unadulterated', 'nutritious', 'healthiness', 'whisper', 'rich source', 'unparalleled', 'perfect', 'authentically', 'robust', 'hint', 'pinch', '100%'],
    factCheckWords: ['protein', 'energy', 'omega 3 fatty acids', 'daily value', 'per serving', 'antioxidant', 'low fat', 'lowfat', 'gluten free', 'low sodium', 'low cholesterol', 'low saturated fat', 'good source', 'excellent source', 'whole wheat', '\\blight\\b', 'reduced', 'added', 'added', 'extra', 'plus', 'fortified', 'enriched', 'more', 'less', 'high', 'rich in', 'contains', 'provides', 'lean', 'extra lean', 'high potency', 'modified', '\\bno\\b', 'free', 'zero', 'amount', 'keto', 'low carb', 'antibiotics', 'hormones', 'growth hormones', 'no sugar added', 'msg', 'cage free', 'made with', 'fresh']
}

const DescriptionGenerator = () => {
    const { 
        products, setProducts,
        URL,
        generated, setGenerated,
        imageOverrides, setImageOverrides,
        choosingSheet, setChoosingSheet,
        sheetChoices, setSheetChoices,
        wb, setWb,
        sheet, setSheet
    } = useGeneratorContext();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const dt = useRef(null);

    const setImageOverride = (index, value) => {
        const newImageOverrides = [...imageOverrides];
        newImageOverrides[index] = value;
        setImageOverrides(newImageOverrides);
    }

    const goToBrandTileUploadPage = () => {
        navigate('/generator/brand-tiles');
    }

    return (
        <div className="container min-w-screen surface-ground">
            <div className="container w-11 min-h-screen mx-auto ">
                <div className="flex justify-content-between align-items-center mb-5">
                    <ThinkhausHeaderTitle>Product Description Generator</ThinkhausHeaderTitle>
                    <Button
                        label="Brand Tile Upload"
                        onClick={goToBrandTileUploadPage}
                        icon='pi pi-chevron-circle-right'
                        iconPos='right'
                        className='p-button-rounded p-button-secondary '
                    />
                </div>
                <Dialog className="h-12rem w-15rem" visible={choosingSheet} onHide={() => setChoosingSheet(false)}>
                    <h5>Select Sheet:</h5>
                    <DropdownSelect
                        wb={wb}
                        sheet={sheet}
                        setSheet={setSheet}
                        setChoosingSheet={setChoosingSheet}
                        setProducts={setProducts}
                        sheetChoices={sheetChoices}
                        id='DialogDropdown'
                    />
                </Dialog>
                <div className="flex flex-row border-3 border-500">

                    {!products.length &&
                        <UploadButton
                            products={products}
                            setProducts={setProducts}
                            setChoosingSheet={setChoosingSheet}
                            setSheet={setSheet}
                            setSheetChoices={setSheetChoices}
                            setWb={setWb}
                        />
                    }
                    {products.length > 0 &&
                        <>
                            <RunAllButton
                                URL={URL}
                                products={products}
                                setProducts={setProducts}
                                setLoading={setLoading}
                                setGenerated={setGenerated}
                                setError={setError}
                            />
                            <Button
                                className="p-button-danger ml-3 my-3"
                                icon="pi pi-ban"
                                label="Clear Data"
                                onClick={() => { setProducts([]); setSheet(''); setSheetChoices([]); setGenerated(false) }}
                            />
                        </>
                    }
                    <ExportButtons products={products} generated={generated} wordLists={wordLists} URL={URL} dt={dt} />
                    {sheetChoices.length > 0 && (
                        <DropdownSelect
                            wb={wb}
                            sheet={sheet}
                            setSheet={setSheet}
                            setChoosingSheet={setChoosingSheet}
                            setProducts={setProducts}
                            sheetChoices={sheetChoices}
                        />
                    )}

                </div>
                <MainTable
                    products={products}
                    setProducts={setProducts}
                    setLoading={setLoading}
                    setGenerated={setGenerated}
                    setError={setError}
                    wordLists={wordLists}
                    imageOverrides={imageOverrides}
                    dt={dt}
                />

                {products.length > 0 &&
                    <div className='container flex flex-column align-items-start justify-content-between'>
                        <div className='mb-3'>
                            <div className='flex flex-row flex-wrap mr-3 center'>
                                {imageOverrides.map((imageOverride, index) => (
                                    <div key={"Carousel " + index} className="p-col-12 p-md-4 p-sm-6 mr-2 mb-2 border-3 border-500 p-2 border-round">
                                        <p className='text-xl text-primary font-main mt-1 mb-1  border-500'>
                                            Carousel #{index + 1}
                                            {index === 0 ?
                                                <>
                                                    <Tooltip className='custom-tooltip' target=".pi-info-circle" />
                                                    <i
                                                        className="ml-3 pi pi-info-circle"
                                                        data-pr-position="top"
                                                        data-pr-tooltip="Leaving this to Default will still run the default logic and could override the image in Syndigo. If you want to leave the Carousel 1 image thats already in Syndigo, select 'KEEP EXISTING IMAGE' " />
                                                </>
                                                : []}
                                        </p>

                                        <ImageOverrideDropdown
                                            imageOverride={imageOverride}
                                            setImageOverride={(value) => setImageOverride(index, value)}
                                            dataTestid={`ImageOverrideDropdown-${index}`}
                                            addValue={index === 0 ? [{ label: 'KEEP EXISTING IMAGE', value: 'KEEP EXISTING IMAGE' }] : null}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        <SyndigoSubmitButton
                            products={products}
                            URL={URL}
                            buttonText='Submit All'
                            imageOverrides={imageOverrides}
                        />
                    </div>
                }

                <Loader loading={loading} setLoading={setLoading} />

                <Dialog className="h-12rem" header="Oops..." visible={error} closable onHide={() => setError(false)}>
                    <div className="container">
                        <p className="text-primary text-4xl">Something went wrong... Please try again.</p>
                        {/* <p className="text-primary text-4xl">{errorText}</p> */}
                    </div>
                </Dialog>

                <p>Version: 1.0.1</p>
            </div>
        </div>
    );
}

export default DescriptionGenerator;

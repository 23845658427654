import ExcelJS from 'exceljs';
import { CSVToArray } from '../../utils/format';

export const generateTableData = (arrays: any[][]): object[] => {
    const headerRow = arrays[0];
    const tableRows = arrays.slice(1);

    // convert arrays to obj with header: value
    return tableRows.map(row => {
        let obj: any = {};
        row.forEach((val, idx) => {
            obj = {
                ...obj,
                [headerRow[idx]]: val
            };
        });
        return obj;
    });
};

export const handleCSVUpload = (e: any, setProducts: Function): void => {
    const reader = new FileReader();
    reader.onload = () => {
        // convert to table rows
        const arrays = CSVToArray(reader.result);
        //parse table rows and align rows to header
        const table = generateTableData(arrays);
        setProducts(table.slice(1));
    };

    reader.readAsBinaryString(e.files[0]);
};

export const handleXLSXUpload = async (
    e: any,
    setWb: Function,
    setChoosingSheet: Function,
    setSheet: Function,
    setSheetChoices: Function,
    setSheetInState: Function,
    setProducts: any
): Promise<void> => {
    const file = e.files[0];
    const buffer = await file.arrayBuffer();
    const workbook = new ExcelJS.Workbook();

    // Read the file into the workbook
    await workbook.xlsx.load(buffer);

    // Set the workbook state
    setWb(workbook);

    // Get names of all sheets in the workbook
    const sheetNames = workbook.worksheets.map(sheet => sheet.name);
    const hasMultipleSheets = sheetNames.length > 1;

    if (hasMultipleSheets) {
        setChoosingSheet(true);
        setSheet('');
        setSheetChoices(sheetNames);
    } else {
        const firstSheet = workbook.getWorksheet(sheetNames[0]);
        setSheetInState(firstSheet, setProducts);
    }
};

export const setSheetInState = (worksheet: ExcelJS.Worksheet, setProducts: Function): void => {
    let data: any[] = [];
    let headers: string[] = [];

    worksheet.eachRow((row, rowNumber) => {
        let rowData: any = row.values;
        rowData.shift(); // Remove the first element which is undefined due to how exceljs returns values

        if (rowNumber === 1) {
            // Assuming the first row is the header
            headers = rowData;
        } else {
            // Map each row to an object based on headers
            let obj = headers.reduce((acc, header, index) => {
                // Check if rowData[index] is an object and has a 'result' property

                if (typeof rowData[index] === 'object' && rowData[index] !== null) {
                    if ('result' in rowData[index]) {
                        if (typeof rowData[index].result === 'object' && 'error' in rowData[index].result) {
                            // If there's an error in the cell, return an empty string
                            acc[header] = '';
                        } else {
                            // Assign the result if it's a normal result
                            acc[header] = rowData[index].result;
                        }
                    } else {
                        // For any other case where rowData[index] is an object but no known properties are detected
                        acc[header] = rowData[index].text ? rowData[index].text : '';
                    }
                } else {
                    // For cells without formulas or where the value itself is not an object
                    acc[header] = rowData[index];
                }
                return acc;
            }, {});
            data.push(obj);
        }
    });
    // Once all rows are processed, set the products with the new data structure
    setProducts(data);
}

export const handleImport = (e: any, setProducts: Function, setWb: Function, setChoosingSheet: Function, setSheet: Function, setSheetChoices: Function): void => {
    const file = e.files[0];

    if (file.type === 'text/csv') {
        // CSV workflow
        handleCSVUpload(e, setProducts);
    } else {
        handleXLSXUpload(e, setWb, setChoosingSheet, setSheet, setSheetChoices, setSheetInState, setProducts);
    }
};

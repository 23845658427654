import React, { createContext, useContext, useState } from 'react';
import ExcelJS from 'exceljs';
const { REACT_APP_THINKHAUS_URL
} = process.env

// Create the context
const GeneratorContext = createContext(null);

// Create the provider component
export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [generated, setGenerated] = useState(false);
    const [imageOverrides, setImageOverrides] = useState(Array(6).fill('Default'));
    const [choosingSheet, setChoosingSheet] = useState(false);
    const [sheetChoices, setSheetChoices] = useState([]);
    const [wb, setWb] = useState<ExcelJS.Workbook | null>(null);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [results, setResults] = useState([]);
    const [sheet, setSheet] = useState('');
    const [brandTile, setBrandTile] = useState('');
    const URL = REACT_APP_THINKHAUS_URL;

    return (
        <GeneratorContext.Provider
            value={{
                products, setProducts,
                URL,
                generated, setGenerated,
                imageOverrides, setImageOverrides,
                choosingSheet, setChoosingSheet,
                sheetChoices, setSheetChoices,
                wb, setWb,
                sheet, setSheet,
                dropdownOptions, setDropdownOptions,
                results, setResults,
                brandTile, setBrandTile
            }}>
            {children}
        </GeneratorContext.Provider>
    );
};

// Custom hook to use the ProductsContext
export const useGeneratorContext = () => useContext(GeneratorContext);

import React from 'react';
import { Session } from "@supabase/supabase-js";
import axios, { AxiosRequestConfig } from "axios";
import { Tooltip } from "primereact/tooltip";

interface ProductTitlesProps {
    showTitles: string[];
}

export const ProductTitles = ({showTitles}) => {
    return (
        <div style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
            {showTitles.map((product, index) => (
                <p key={product + index} style={{ margin: 0, padding: '5px 0' }}>
                    {product || 'Unnamed Product'}
                </p>
            ))}
        </div>
    );
};

export const ProductResponses = (showTitles, responses) => {
    if (!Array.isArray(showTitles)) {
        console.error("Invalid showTitles: expected an array, received:", showTitles);
        return null;  // or return a default value, or an empty element
    }
    return (
        <div style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
            {showTitles.map((product, index) => (
                <div key={product + index} style={{ display: 'flex', alignItems: 'center', margin: '5px 0', overflow: "visible" }}>
                    <p style={{ margin: 0, padding: '5px', flexGrow: 1 }}>
                        {product || 'Unnamed Product'}
                    </p>
                    {iconSelect(responses, index)}
                </div>
            ))}
        </div>
    );
};

const iconSelect = (responses: [], index) => {
    let element;
    switch (responses[index]) {
        case 'Success':
            element = (
                <div className="icon-container" data-testid={`icon-success-${index}`}>
                    <i className="pi pi-check" style={{ fontSize: '2em', color: 'green' }}></i>
                </div>
            );
            break;

        case 'Warning: Product has not changed since last publication':
            element = (
                <div className={`icon-container icon-container-${index} my-icon-container`} data-testid={`icon-warning-${index}`}>
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2em', color: 'orange' }}></i>
                    <Tooltip className='orange-tooltip' target={`.icon-container-${index}`} content={responses[index]} />
                </div>
            );
            break;

        default:
            element = (
                <div className={`icon-container icon-container-${index} my-icon-container`} data-testid={`icon-error-${index}`}>
                    <i className="pi pi-times" style={{ fontSize: '2em', color: 'red' }}></i>
                    <Tooltip className='red-tooltip' target={`.icon-container-${index}`} content={responses[index]} />
                </div>
            );
            break;
    }
    return (element);
}

const parseBullets = (bulletsString: string): { [key: string]: string } => {
    if (bulletsString === undefined) {
        return null;
    }

    // Split by newline, remove carriage returns and trim spaces
    const bulletsArray = bulletsString.split('\n').map(bullet =>
        bullet.replace(/\r$/, '').trim() // Removes any trailing carriage return and trims the string
    );

    const bulletsJSON = bulletsArray.reduce((acc, bullet, index) => {
        if (index < 6) { // There are only bullets 1 - 6 in Syndigo
            const key = `Feature - Benefit Bullet ${index + 1}`;
            acc[key] = bullet;
        }
        return acc;
    }, {} as { [key: string]: string });

    return bulletsJSON;
}

export const handleSubmitDialog = (products: any[], setShowSubmitDialog: Function, setShowTitles: Function) => {
    setShowSubmitDialog(true)

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    setShowTitles(productsArray?.map((product, index) => (
        product.Product_Title || 'Unnamed Product'
    )));
}

export const handleSubmitClick = async (products: any[], URL, setIsLoading: Function, setShowPublishDialog: Function, setShowSubmitDialog: Function, setResponses: Function, setErrorText: Function, session: Session, imageOverrides: string[]) => {
    setIsLoading(true)
    setShowSubmitDialog(true);

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    let formatProduct = {
        products: productsArray.map(product => {
            const bullets = parseBullets(product.bullets);
            return {
                "Product Name": product?.Product_Title,
                "GTIN": product?.UPC,
                "Customer Facing Size": product?.Size,
                "Marketing Copy": product?.description,
                ...bullets,
            }
        }),
        imageOverrides: imageOverrides
    }

    try {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": `Bearer ${session.access_token}`
            }
        }

        const response = await axios.post(URL + 'api/update-products', formatProduct, config);

        setResponses(productsArray?.map((product, index) => { 
            const pResponse = (response?.data?.LookupErrors?.[index] ?? response?.data?.SyndigoErrors?.[index]) ?? 'Success';
            
            // Set error text to display if a product failed to update
            if (pResponse !== 'Success') {
                setErrorText('An error was detected when updating in Syndigo, please resolve the error before trying again')
            }
            return pResponse;
        }));
    } catch (e) {
        console.error(e);
    } finally {
        setShowSubmitDialog(false);
        setShowPublishDialog(true);
        setIsLoading(false);
    }

}

type HandlePublishClickParams = {
    products: any[];
    URL: string;
    setIsLoading: Function;
    setShowPublishDialog: Function;
    setResponses: Function;
    setErrorText: Function;
    setShowEndDialog: Function;
    MainImageOverride: string;
    session: Session;
}


export const handlePublishClick = async (params: HandlePublishClickParams) => {
    const { products, URL, setIsLoading, setShowPublishDialog, setResponses, setErrorText, setShowEndDialog, MainImageOverride, session } = params;

    setIsLoading(true);
    setShowPublishDialog(true);

    // Ensure products is always an array
    const productsArray = Array.isArray(products) ? products : [products];

    let formatProduct = productsArray.map(product => {
        return {
            "ProductName": product?.Product_Title,
            "GTIN": product?.UPC,
            "imageOverride": MainImageOverride
        }
    })

    try {
        const config: AxiosRequestConfig = {
            headers: {
                "Authorization": `Bearer ${session.access_token}`
            }
        }

        const responses = await Promise.allSettled(
            formatProduct.map(product =>
                axios.post(URL + 'api/publish', { product }, config)
            )
        );

        const results = responses.map((result, index) => {
            if (result?.status === 'fulfilled') {
                return {
                    GTIN: formatProduct[index].GTIN,
                    ProductName: formatProduct[index].ProductName,
                    response: result.value.data  // Accessing data directly from the resolved value
                };
            } else {
                let resultError = result?.reason?.response?.data?.responseData || 'Publish Request Failed: Check product information '
                return {
                    GTIN: formatProduct[index].GTIN,
                    ProductName: formatProduct[index].ProductName,
                    response: { responseData: { publishResponse: { Message: resultError } } }
                };
            }
        });

        //Set Responses from publish attempt
        setResponses(results?.map((product) => {
            let pResponse = '';
            const routeToResponse = product?.response?.responseData?.publishResponse;
            if (routeToResponse?.PublicationStarted === true) {
                pResponse = 'Success';
            } else if (routeToResponse?.Message === 'Product has not changed since last publication') {
                pResponse = 'Warning: ' + routeToResponse?.Message;
            } else {
                pResponse = routeToResponse?.Message || 'ERROR: cannot fetch response data';
                setErrorText('An error was detected when publishing in Syndigo, please resolve the error before trying again')
            }

            return pResponse;
        }));

    } catch (e) {
        console.error('Unexpected error:', e);
    }
    finally {
        setShowPublishDialog(false);
        setShowEndDialog(true)
        setIsLoading(false);
    }

}
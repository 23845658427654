import React, { ReactNode } from "react";

type KrogerInfoBoxProps = {
    className?: string,
    children: ReactNode
}

export const KrogerInfoBox = ({ children, className = '' }: KrogerInfoBoxProps) => {

    return (
        <div data-testid='KrogerInfoBox' style={{ color: '#084999' }} className={`flex flex-column border-1 border-gray-100 bg-yellow-100 border-round-sm p-4 ${className}`}>
            {children}
        </div>
    )
}
import { Button } from 'primereact/button';

interface ImageNavigateButtonProps {
    onClick: () => void;
    buttonText: string;
}

export const ImageNavigateButton = ({onClick, buttonText}: ImageNavigateButtonProps) => {
    return (
            <Button
                className='p-button-rounded mr-3 text-lg px-4 py-3'
                style={{ padding: '10px 20px' }}
                onClick={onClick}
                label={buttonText}
                icon='pi pi-chevron-circle-right'
                iconPos='right'
                severity="success"
                id='bulk-images-button'
            />
    )
}
import React from "react";
import { Dropdown } from "primereact/dropdown";
import { useGeneratorContext } from "../../context/GeneratorContext";


type BrandTilesSelectorProps = {
    brandTile: string,
    setBrandTile: (value: string) => void
}

export const BrandTilesSelector = ({ brandTile, setBrandTile }: BrandTilesSelectorProps) => {
    const { dropdownOptions } = useGeneratorContext()

    const handleChange = (e) => {
        setBrandTile(e.value);
    }

    return (
        <>
            <Dropdown
                className='w-full w-min-min'
                placeholder="Select a Brand Tile..."
                value={brandTile}
                options={dropdownOptions
                    .filter(item => item.commodity && item.assetid)
                    .map(item => {
                        return ({ label: item.commodity, value: item.assetid })
                    })}
                onChange={e => handleChange(e)}
                data-testid='Brand-Tile-Select'
            />
        </>

    );
};
import React, { useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import axios, { AxiosRequestConfig } from "axios";
import { validateGTIN } from '../../utils/SyndigoUtils.js';

import './SyndigoCreateProduct.css';
import '../../App.css';
import { Dropdown } from "primereact/dropdown";
import { KrogerInfoBox } from "../KrogerIntakeForm/KrogerInfoBox";

const { REACT_APP_THINKHAUS_URL } = process.env;

export const SyndigoCreateProduct = () => {
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [upc, setUpc] = useState('');
    const [productName, setProductName] = useState('');
    const [brandName, setBrandName] = useState('');
    const [otherBrandName, setOtherBrandName] = useState('');

    const toast = React.useRef(null);

    const { session, user } = useAuth();

    const brandOptions = [
        'Private Selection',
        'Other...'
    ]

    const isFormValid = (): boolean => {
        return (loading || upc.length < 13 || !productName || !brandName || (brandName === 'Other...' && !otherBrandName)) as boolean;
    }

    const handleClear = () => {
        setBrandName('');
        setOtherBrandName('');
        setProductName('');
        setError([]);
        setUpc('');
    }

    const createUPC = async (submitUPC) => {
        setLoading(true);

        try {
            const body = {
                upc: upc,
                productName: productName,
                brandName: brandName !== 'Other...' ? brandName : otherBrandName
            }

            const config: AxiosRequestConfig = {
                headers: {
                    "Authorization": `Bearer ${session.access_token}`
                }
            }

            const response = await axios.post(REACT_APP_THINKHAUS_URL + 'api/syndigo/create-product', body, config)

            if (response.status === 200) {

                toast.current.show({
                    severity: 'success',
                    summary: 'UPC CREATED',
                    detail: `UPC # ${upc} was successfully sent to be created.`,
                    life: 6000
                })
                // Clear the form state
                handleClear();
            }
        } catch (error) {
            console.log(error);

            toast.current.show({
                severity: 'error',
                summary: `${upc || "UPC"} CREATION FAILED`,
                detail: `${error.response.data.message}`,
                sticky: true
            })
        } finally {
            setLoading(false);
        }
    }

    const handleCreateClick = () => {
        const gtinCheck = validateGTIN(upc);

        if (gtinCheck === "") {
            toast.current.show({
                severity: 'error',
                summary: 'INVALID UPC',
                detail: 'UPC is not valid, please fix before submitting again',
                life: 6000
            })
            return;
        }

        confirmDialog({
            message: (
                <div>
                    Are you sure you want to create UPC # {gtinCheck}?
                    <i
                        id="info-tooltip"
                        className="pi pi-info-circle"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                    />
                    {/* Tooltip attached to info icon */}
                    <Tooltip className='orange-tooltip' target="#info-tooltip">
                        <p>
                            Different from the number you inputted? If the product you
                            inserted was a 13-digit UPC, we convert it to the correct 14-digit
                            UPC to be uploaded to Syndigo.
                        </p>
                    </Tooltip>
                </div>
            ),
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                createUPC(gtinCheck)
            }
        })
    }

    return (
        <div className="page-background flex flex-column w-full font-kroger px-4">
            <div className="syndigo-create-product border-3 border-round" >
                <h2 className="flex justify-content-center">Create Product in Syndigo</h2>
                <KrogerInfoBox className='mb-3' >
                    <p>
                        If a product is missing in Syndigo, use this form to create the item. The product you submit will be sent
                        to the 'Brand Library' recipient for approval. Once approved, the product will be added to Syndigo's product index
                        and listed in the active directory, where it can be updated with content.
                    </p>
                    <p>
                        To submit this form, please ensure the following:
                    </p>
                    <ul>
                        <li>A product name must be provided.</li>
                        <li>A brand name must be selected.</li>
                        <li>A valid 13-digit or 14-digit UPC must be entered.</li>
                    </ul>
                </KrogerInfoBox>

                <div className="flex justify-content-between mb-3">
                    <div className='w-8 mr-6'>
                        <label htmlFor="brandNameDropdown">Brand Name</label>
                        <Dropdown
                            className='w-full border-round mr-4'
                            id="brandNameDropdown"
                            placeholder="Select a Brand Name..."
                            value={brandName}
                            options={brandOptions}
                            onChange={e => setBrandName(e.target.value)}
                            data-testid='Brand-Name-Select'
                        />
                    </div>

                    <div className='w-full'>
                        <label htmlFor="nameinput">Product Name</label>
                        <InputText
                            className='w-full border-round'
                            id='nameinput'
                            placeholder='Please insert product name...'
                            data-testid='Product-Name-Input'
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </div>
                </div>

                <div className="flex justify-content-between align-items-center" style={{ flexWrap: 'wrap' }}>
                    {brandName === 'Other...' && (
                        <div className="flex-1 mr-4">
                            <label htmlFor='otherBrandNameInput'>Specify Brand Name:</label>
                            <InputText
                                className="w-full border-round "
                                placeholder="Please type brand name..."
                                id="otherBrandNameInput"
                                value={otherBrandName}
                                onChange={(e) => setOtherBrandName(e.target.value)}
                                data-testid='Other-BrandName-Input'
                            />
                            <small>i.e. Private Selection</small>
                        </div>
                    )}

                    <div className="flex-1 mr-4">
                        <label htmlFor="upcinput">UPC</label>
                        <InputText
                            className="w-full border-round"
                            id='upcinput'
                            keyfilter="pint"
                            placeholder="Please enter UPC..."
                            value={upc}
                            onChange={(e) => setUpc(e.target.value)}
                            data-testid='Product-UPC-Input'
                        />
                        <small>13-digit or 14-digit</small>
                    </div>

                    <div className="flex align-items-center">
                        <Button
                            label="Create UPC"
                            icon="pi pi-play"
                            className="p-button-rounded p-button-success mx-2 p-2"
                            onClick={handleCreateClick}
                            disabled={isFormValid()}
                            severity="success"
                            data-testid='Button-Create'
                        />
                        <Button
                            label="Clear"
                            icon="pi pi-times"
                            className="p-button-rounded p-button-secondary p-2"
                            onClick={handleClear}
                            disabled={loading || (!upc && (!brandName || !otherBrandName) && !productName)}
                            severity='danger'
                            data-testid='Button-Clear'
                        />
                    </div>
                </div>

                {error.length > 1 ? <>error</> : null}

                <Toast ref={toast} data-testid="toast-component" />
                <ConfirmDialog />
            </div>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProductTitles, ProductResponses, handlePublishClick, handleSubmitClick, handleSubmitDialog } from "./SyndigoSubmitButtonFuncs";
import "../../App.css";
import { useAuth } from "../../context/AuthProvider";

type SyndigoSubmitButtonProps = {
    products: any[],
    URL: string,
    buttonText: string,
    imageOverrides: string[]
};

export const SyndigoSubmitButton = ({ products, URL, buttonText, imageOverrides }: SyndigoSubmitButtonProps) => {
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [showEndDialog, setShowEndDialog] = useState(false);
    const [showTitles, setShowTitles] = useState([]);
    const [responses, setResponses] = useState([]);
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { session } = useAuth();

    useEffect(() => {
        // Check if any responses indicate failure and set the error state
        const hasError = responses.some(response => response !== ('Success' || 'Warning'));
        setIsError(hasError);
    }, [responses]);

    return (
        <>
            <Button onClick={() => handleSubmitDialog(products, setShowSubmitDialog, setShowTitles)} data-testid={buttonText}> {buttonText} </Button>

            <Dialog
                visible={showSubmitDialog}
                className="Syndigo-Submit-Dialog"
                onHide={() => setShowSubmitDialog(false)}
                data-cy-submit-dialog
            >
                {!isLoading ?
                    <>
                        <div className="s-title" data-cy-s-title >Are you sure you want to submit the following to Syndigo?</div>
                        {<ProductTitles showTitles={showTitles} />}
                        <Button
                            className="p-button-primary generate-button my-3 ml-3 align-items-end"
                            icon="pi pi-check"
                            label="Submit"
                            onClick={() => handleSubmitClick(products, URL, setIsLoading, setShowPublishDialog, setShowSubmitDialog, setResponses, setErrorText, session, imageOverrides)}
                            data-testid='submitButton1'
                            data-cy-submit-button
                        />
                        <Button
                            className="p-button-danger ml-3 my-3 align-items-end"
                            icon="pi pi-ban"
                            label="Cancel"
                            onClick={() => setShowSubmitDialog(false)}
                        />
                    </>
                    :
                    <ProgressSpinner className="Dialog-Spinner" />
                }
            </Dialog>

            <Dialog
                visible={showPublishDialog}
                className="Syndigo-Submit-Dialog"
                onHide={() => setShowPublishDialog(false)}
            >
                {!isLoading ?
                    <>
                        <div className="s-title">Syndigo Results</div>
                        {ProductResponses(showTitles, responses)}
                        {isError ?
                            <div style={{ color: 'red', fontWeight: 'bold', paddingTop: '10px', paddingLeft: '10px' }} data-cy-submit-errortext >
                                {errorText}
                            </div>
                            :
                            <Button
                                className="p-button-primary ml-3 my-3 align-items-end"
                                icon="pi pi-file"
                                label="Publish"
                                onClick={() => handlePublishClick({ products, URL, setIsLoading, setShowPublishDialog, setResponses, setErrorText, setShowEndDialog, MainImageOverride: imageOverrides[0], session })}
                                data-cy-publish-button
                            />
                        }
                        <Button
                            className="p-button-danger ml-3 my-3 align-items-end"
                            icon="pi pi-ban"
                            label="Close"
                            onClick={() => setShowPublishDialog(false)}
                        />
                    </>
                    :
                    <ProgressSpinner className="Dialog-Spinner" />
                }
            </Dialog>

            <Dialog
                visible={showEndDialog}
                className="Syndigo-Submit-Dialog"
                onHide={() => setShowEndDialog(false)}
            >
                {!isLoading ?
                    <>
                        <div className="s-title" data-cy-title-publish >Syndigo Publish Results</div>
                        {ProductResponses(showTitles, responses)}
                        {isError ?
                            <div
                                style={{ color: 'red', fontWeight: 'bold', paddingTop: '10px', paddingLeft: '10px' }}
                                data-cy-submit-errortext
                            >
                                {errorText}
                            </div>
                            :
                            <div></div>
                        }
                        <Button
                            className="p-button-danger ml-3 my-3 align-items-end"
                            icon="pi pi-ban"
                            label="Close"
                            onClick={() => setShowEndDialog(false)}
                        />
                    </>
                    :
                    <ProgressSpinner className="Dialog-Spinner" />
                }
            </Dialog>
        </>
    )
};
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import DescriptionGenerator from '../DescriptionGenerator/DescriptionGenerator';
import { BrandTilesUploadPage } from '../BrandTiles/BrandTilesUploadPage';
import ProtectedRoute from '../Auth/ProtectedRoute';
import NavBar from '../Home/NavBar';

const GeneratorWrapper = () => {

    return (
        <>
            <NavBar />
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <DescriptionGenerator />
                        </ProtectedRoute>}
                />
                <Route
                    path="brand-tiles"
                    element={
                        <ProtectedRoute>
                            <BrandTilesUploadPage />
                        </ProtectedRoute>}
                />
            </Routes>
        </>
    );
};

export default GeneratorWrapper;

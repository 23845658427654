import React from 'react';
import { Card } from 'primereact/card';

type Props = {
    pageTitle?: string;
}
export const UnderConstruction = ({pageTitle = ''}: Props) => {
    return ( 
        <div style={{background: '#084999'}}>
                 <Card className="zoomin animation-duration-3000 col-12 font-kroger border-round-sm flex flex-column" style={{background: '#084999', color: 'white'}}>
                    <span className="w-screen">
                        <h1 className="font-main text-center">You're looking for {pageTitle}...</h1>
                        <h1 className="font-main text-center">Sorry! We're working on some things.</h1>
                        <div className="w-full flex justify-content-center">
                            <i className="pi pi-exclamation-triangle" style={{ fontSize: '15rem', color: 'orange' }} />
                        </div>
                        <h3 className="font-main text-center" style={{color: 'orange'}}>Please check back later.</h3>
                    </span>
                </Card>
        </div>
    );
}

import React, { ReactNode } from "react";

const logo = require('../../assets/th-logo.png');

type ThinkhausHeaderTitleProps = {
    children: ReactNode
}

export const ThinkhausHeaderTitle = ({ children }: ThinkhausHeaderTitleProps) => {
    return (
        <div className="header flex flex-row justify-content-evenly w-full">
            <img className="max-h-10rem" src={logo} alt="Thinkhaus Logo" />
            <div className="my-auto">
                <p className="text-4xl text-primary font-main">{children}</p>
            </div>
        </div>
    )
}
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { supabaseClient } from '../../config/supabase-client';
import './authStuff.css'

const PasswordResetPage = () => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const accessToken = query.get('token'); // Get the token from URL
  //   const email = query.get('email'); // Get the email from URL

  //   const verifyOTP = async () => {
  //       await supabaseClient.auth.verifyOtp({ email: email, token: accessToken, type: 'magiclink'})
  //   }

  //   verifyOTP();
  // }, []);

  const handlePasswordReset = async () => {
    // setLoading(true);
    try {
        // const query = new URLSearchParams(location.search);
        // const accessToken = query.get('token'); // Get the token from the query parameter
        // console.log(accessToken)
        // //TODO: Verify access Token 
    
        // if (!accessToken) {
        //   alert('Invalid or missing token.');
        //   return;
        // }

        if(password !== passwordRepeat){
            alert('Passwords do not match!');
            return;
        }

        const { error } = await supabaseClient.auth.updateUser({password: password})
        
        if (error) throw error;
        alert('Your password has been updated. You can now log in with your new password.');
        navigate('/login'); // redirect to login page
    } catch (error) {
      alert(`Error: ${error.message}`);
    } finally {
    //   setLoading(false);
    }
  };


  return (
    <div className='password-container'>
      <div className="p-d-flex p-jc-center p-ai-center" style={{ height: '100vh' }}>
        <div className="card">
          <h1>Reset Your Password</h1>
          <div className="password-text-container">
            <span className="p-float-label">
              <InputText
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
              <label htmlFor="password">New Password</label>
            </span>
            <span className="p-float-label">
              <InputText
                id="password"
                value={passwordRepeat}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                type="password"
              />
              <label htmlFor="password">Repeat Password</label>
            </span>
          </div>
          <Button label="Update Password" onClick={handlePasswordReset} />
        </div>
      </div>
    </div>
  );
};

export default PasswordResetPage;

import React from "react";
import NavBar from "../Home/NavBar";
import Header from "../Header/Header";
import KrogerIntakeForm from "./KrogerIntakeForm";

export const KrogerIntakeFormWrapper = () => {
    return (
        <div className="min-width-scroll">
            <NavBar />
            <>
                <Header />
                <KrogerIntakeForm />
            </>
        </div>
    )
}
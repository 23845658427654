import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";

const ProtectedRoute = ({ children }: any) => {
    const { user, session } = useAuth()

    if (!session) {
        // user is not authenticated
        return <Navigate to="/" />;
    }
    return <>{children}</>
};

export default ProtectedRoute
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import NavBar from "../Home/NavBar";
import { ImageNavigateButton } from "../SingleImageUpload/ImageNavigateButton";
import BulkImageUpload from "./BulkImageUpload";
import { KrogerInfoBox } from "../KrogerIntakeForm/KrogerInfoBox";

const positionOptions = [
    { label: 'Carousel Image 1/MHRI', value: 'Main Product Image' },
    { label: 'Carousel Image 2', value: 'Detailed Product View 1' },
    { label: 'Carousel Image 3', value: 'Detailed Product View 2' },
    { label: 'Carousel Image 4', value: 'Detailed Product View 3' },
    { label: 'Carousel Image 5', value: 'Detailed Product View 4' },
    { label: 'Carousel Image 6', value: 'Detailed Product View 5' }
];

const uploadURL = 'api/upload-image'

export const MarksBulkImageUploadWrapper = () => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        navigate('/marks-single-image-upload');
    }

    return (
        <div className="min-width-scroll">
            <NavBar />
            <div className='flex align-items-end justify-content-between' style={{ background: '#084999', color: 'white' }}>
                <Header />
                <ImageNavigateButton onClick={handleOnClick} buttonText='Single Product?' />
            </div>
            <BulkImageUpload positionOptions={positionOptions} uploadURL={uploadURL} >
                <div className="flex flex-column w-full font-kroger px-4 p-align-center">
                    <h2 className='text-center'> Marks Bulk Image Upload </h2>
                    <KrogerInfoBox className='mb-3 mx-8 w-min-full justify-center overflow-auto max-h-20rem' >
                        <p>
                            If you have multiple GTINs (UPCs) for a single view, this upload tool will process each product
                            for the selected slot. You can add files by clicking the 'Choose' button or by dragging and dropping
                            them into the table area. After adding the images and filling in all required data, click the green
                            'Upload Images to Syndigo' button to upload the images. This will update the corresponding fields in
                            the Syndigo Product Index. <br /> 
                            <b><i>Note:</i> UPCs will be automatically entered if included in the filename (<i>i.e. 0001223334444_S.jpg</i>)</b>
                        </p>
                        <p>
                            To submit this form, please ensure the following:
                        </p>
                        <ul>
                            <li>A position is selected.</li>
                            <li>A UPC is entered for <b><i>each</i></b> product image.</li>
                        </ul>
                    </KrogerInfoBox>
                </div>
            </BulkImageUpload>

        </div>
    );
}